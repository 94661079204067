<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="4"> Semua Perusahaan </CCol>
              <CCol md="8" class="text-right"> </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md">
                    <div class="form-group">
                      <b for="">Provinsi</b>
                      <v-select
                        :options="list_daerah"
                        label="pr_nama"
                        v-model="provinsi_selected"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="form-group">
                      <b for="">Kabupaten / Kota</b>
                      <v-select
                        :options="provinsi_selected.kota"
                        label="k_nama"
                        v-model="kota_selected"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md">
                    <div class="form-group">
                      <b for="">Cari</b>
                      <input
                        type="text"
                        class="form-control"
                        id="search-table"
                        placeholder="Search.."
                        v-model="searchData"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-md-5 d-flex justify-content-start"
                    style="margin-bottom: 10px; gap: 10px"
                  >
                    <div
                      class="btn btn-sm btn-primary"
                      v-on:click="searchMaster"
                    >
                      <i class="fa fa-filter"></i> Filter
                    </div>
                    <div class="btn btn-sm btn-success" v-on:click="getExcel">
                      <i class="fa fa-download"></i> Excel
                    </div>
                    <div
                      class="btn btn-sm btn-danger"
                      v-on:click="getPDFLink()"
                    >
                      <i class="fa fa-download"></i> {{ buttonLabel }}
                    </div>
                  </div>
                </div>
              </div>

              <CCol sm="12">
                <CRow>
                  <CCol sm="12">
                    <table
                      class="table table-sm table-bordered table-responsive"
                      style="width: 100%"
                    >
                      <thead class="bg-dark text-white">
                        <tr>
                          <th>No</th>
                          <th>NIB</th>
                          <th>Jenis Perseroan</th>
                          <th>Nama Perusahaan</th>
                          <th>Nama Penanggung Jawab</th>
                          <th>NPWP</th>
                          <th>Email</th>
                          <th>Telepon</th>
                          <th>Provinsi</th>
                          <th>Kabupaten / Kota</th>
                          <th>Alamat</th>
                          <th>Pembaruan Terakhir</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template>
                          <tr v-if="loadingTable">
                            <td colspan="6">
                              <i class="fa fa-spin fa-refresh"></i> Loading...
                            </td>
                          </tr>
                          <tr
                            v-for="(row_data, key_perusahaan) in dataintable"
                            :key="key_perusahaan"
                            v-else
                          >
                            <td>
                              <template>
                                <div v-if="key_perusahaan + 1 == 10">
                                  {{ curent_page }}0
                                </div>
                                <div v-else>
                                  {{
                                    curent_page - 1 != 0 ? curent_page - 1 : ""
                                  }}{{ key_perusahaan + 1 }}
                                </div>
                              </template>
                            </td>
                            <td>{{ row_data.nib }}</td>
                            <td>{{ row_data.bentuk_perusahaan_id }}</td>
                            <td>{{ row_data.p_nama }}</td>
                            <td>{{ row_data.p_penanggung_jawab }}</td>
                            <td>{{ row_data.p_npwp }}</td>
                            <td>{{ row_data.p_email }}</td>
                            <td>{{ row_data.p_telp }}</td>
                            <td>{{ row_data.nama_prov }}</td>
                            <td>{{ row_data.nama_kota }}</td>
                            <td>{{ row_data.p_alamat }}</td>
                            <td>{{ row_data.pembaharuan_akhir }}</td>
                            <td>
                              <router-link
                                :to="{
                                  name: 'detailPerusahaan',
                                  params: { id_perusahaan: row_data.id },
                                }"
                                class="btn btn-info btn-sm"
                                ><i class="fa fa-eye"></i> Detail</router-link
                              >
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <nav aria-label="pagination">
                      <ul class="pagination justify-content-start">
                        <!---->
                        <!-- {{ page.active ? 'active' : '' }} -->
                        <!-- <li v-for="(page, key_page) in paging" :key="key_page" v-bind:class="{ 'active': page.active }" class="page-item">
                                                    <div v-on:click="toPage(page.url)" class="page-link c-page-link-number"> 
                                                        <div v-html="page.label"></div>
                                                    </div>
                                                </li> -->
                        <li
                          v-for="(page, key_page) in paging"
                          :key="key_page"
                          :class="[
                            'page-item',
                            { active: page.active, disabled: !page.url },
                          ]"
                        >
                          <a
                            href="#"
                            @click.prevent="page.url && toPage(page.url)"
                            class="page-link"
                          >
                            <span v-html="page.label"></span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <br />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
const dataintable = [];
export default {
  name: "DaftarPerusahaan",
  components: {},
  data() {
    return {
      dataintable: [],
      paging: [],
      show_data_to: "",
      show_data_from: "",
      total_data: "",
      curent_page: "",
      loadingTable: true,
      searchData: "",
      list_daerah: [],
      provinsi_selected: [],
      kota_selected: [],
      excel_url: "",
      buttonLabel: "Export PDF",
      isDownloading: false,
    };
  },
  methods: {
    getExcel() {
      // alert(this.excel_url);
      // return false;
      var win = window.open(this.excel_url, "_blank");
      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    deleteData(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Anda tidak bisa mengembalikan data yang dihapus!",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Hapus`,
        denyButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire({
            title: '<i class="fa fa-refresh fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          axios
            .delete(this.apiLink + "api/crud/perusahaan", {
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
              data: {
                id: id,
              },
            })
            .then((response) => {
              var res_data = response.data;
              // console.log(res_data);
              if (res_data.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_data.data.message,
                });
              } else {
                let timerInterval;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Data perusahaan berhasil dihapus.",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                  didOpen: () => {
                    timerInterval = setInterval(() => {
                      const content = Swal.getContent();
                      if (content) {
                        const b = content.querySelector("b");
                        if (b) {
                          b.textContent = Swal.getTimerLeft();
                        }
                      }
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    window.location.reload();
                  }
                });
              }
            });
        } else if (result.isDenied) {
          Swal.close();
        }
      });
    },
    toPage(url) {
      this.loadingTable = true;
      // var numpage = url.replace(this.apiLink+'api/crud/perusahaan?page=','');
      axios
        .get(url, {
          // params: {
          //     page: numpage,
          // },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_perusahaan = response.data;

          var res_perusahaan_data = res_perusahaan.data.list;
          console.log(res_perusahaan);
          if (res_perusahaan.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_perusahaan.data.message,
            });
          } else {
            this.loadingTable = false;
            this.dataintable = res_perusahaan_data.data;
            this.paging = res_perusahaan_data.links;
            this.show_data_from = res_perusahaan_data.from;
            this.show_data_to = res_perusahaan_data.to;
            this.total_data = res_perusahaan_data.total;
            this.curent_page = res_perusahaan_data.current_page;
            this.excel_url = res_perusahaan.data.excel_url;
            console.log(this.dataintable);
          }
        });
    },
    color(value) {
      let $color;
      if (value <= 25) {
        $color = "info";
      } else if (value > 25 && value <= 50) {
        $color = "success";
      } else if (value > 50 && value <= 75) {
        $color = "warning";
      } else if (value > 75 && value <= 100) {
        $color = "danger";
      }
      return $color;
    },
    getBadge(status) {
      if (status) {
        return "success";
      } else {
        return "danger";
      }
    },
    detailPermohonan(item) {
      this.$router.push("DetailPermohonan");
    },
    searchMaster() {
      this.loadingTable = true;
      axios
        .get(this.apiLink + "api/crud/perusahaan", {
          params: {
            search: this.searchData,
            provinsi: this.provinsi_selected?.id,
            kota: this.kota_selected?.id,
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          var res_perusahaan = response.data;

          var res_perusahaan_data = res_perusahaan.data.list;
          console.log(res_perusahaan);
          if (res_perusahaan.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_perusahaan.data.message,
            });
          } else {
            this.loadingTable = false;
            this.dataintable = res_perusahaan_data.data;
            this.paging = res_perusahaan_data.links;
            this.show_data_from = res_perusahaan_data.from;
            this.show_data_to = res_perusahaan_data.to;
            this.total_data = res_perusahaan_data.total;
            this.curent_page = res_perusahaan_data.current_page;
            this.excel_url = res_perusahaan.data.excel_url;
            console.log(this.dataintable);
          }
        });
    },
    getPDFLink() {
      this.buttonLabel = "Downloading data...";
      this.isDownloading = true;
      let fileName = "Laporan Perusahaan Aktif.pdf";

      axios
        .get(this.apiLink + "api/pdf_perusahaan", {
          params: {
            search: this.searchData,
            provinsi: this.provinsi_selected?.id,
            kota: this.kota_selected?.id,
          },
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          if (response.status != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Data tidak ditemukan",
            });
          } else {
            const fileURL = window.URL.createObjectURL(
              new Blob([response.data])
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
          }
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        })
        .catch((error) => {
          console.error("Error during PDF download", error);
          this.buttonLabel = "Export PDF";
          this.isDownloading = false;
        });
    },
  },
  beforeCreate() {
    axios
      .get(this.apiLink + "api/crud/perusahaan", {
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_perusahaan = response.data;

        var res_perusahaan_data = res_perusahaan.data.list;
        console.log(res_perusahaan);
        if (res_perusahaan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_perusahaan.data.message,
          });
        } else {
          this.loadingTable = false;
          this.dataintable = res_perusahaan_data.data;
          this.paging = res_perusahaan_data.links;
          this.show_data_from = res_perusahaan_data.from;
          this.show_data_to = res_perusahaan_data.to;
          this.total_data = res_perusahaan_data.total;
          this.curent_page = res_perusahaan_data.current_page;
          this.excel_url = res_perusahaan.data.excel_url;
          console.log(this.dataintable);
        }
      });
    axios
      .get(this.apiLink + "api/master/chain_daerah", {
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_daerah = response.data;
        var res_daerah_data = res_daerah.data;
        // console.log(res_daerah_data);
        if (res_daerah.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_daerah.data.message,
          });
        } else {
          this.list_daerah = res_daerah_data.master;
          console.log(this.list_daerah);
        }
      });
  },
};
</script>